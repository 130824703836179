import { Text, View } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global'

const HomeScreen = ({ route }) => {
  const { user } = route.params

  return (
    <View style={[globalStyles.container]}>
      <Text style={[globalStyles.text]}>Welcome {user}!</Text>
    </View>
  )
}

export default HomeScreen
