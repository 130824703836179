import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Image,
  Alert
} from 'react-native'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global'

// components
import CustomButton from '../shared/button'

const LoginScreen = ({
  email,
  setEmail,
  password,
  setPassword,
  setIsGuest
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const navigation = useNavigation()

  const handleSignIn = () => {
    if (!email || !password) {
      Alert.alert('Error', 'Please enter email and password!')
    } else {
      setIsGuest(false)
      navigation.navigate('Home', { user: email })
    }
  }

  const handleGuest = () => {
    setIsGuest(true)
    navigation.navigate('Home', { user: 'guest' })
  }

  useEffect(() => {
    if (email && password) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }

    return () => {}
  }, [email, password])

  return (
    <View style={[globalStyles.container, globalStyles.primaryBgColor]}>
      <View style={styles.headerLogoWrapper}>
        <Image
          source={require('../assets/logos/gmc_logo.png')}
          style={styles.headerLogo}
        />
      </View>
      <ScrollView behavior="padding">
        {email !== '' && (
          <Text
            style={[
              globalStyles.text,
              globalStyles.fs12,
              globalStyles.textUppercase,
              globalStyles.lightTextColor
            ]}
          >
            Email
          </Text>
        )}
        <TextInput
          placeholder="Email"
          placeholderTextColor="rgba(255,255,255,.3)"
          value={email}
          onChangeText={(text) => setEmail(text)}
          style={[globalStyles.input, globalStyles.text]}
          autoCapitalize="none"
          required
        />
        <View style={styles.passwordField}>
          {password !== '' && (
            <Text
              style={[
                globalStyles.text,
                globalStyles.fs12,
                globalStyles.textUppercase,
                globalStyles.lightTextColor,
                globalStyles.mt2
              ]}
            >
              Password
            </Text>
          )}
          <TextInput
            placeholder="Password"
            placeholderTextColor="rgba(255,255,255,.3)"
            value={password}
            onChangeText={(text) => setPassword(text)}
            style={[globalStyles.input, globalStyles.text]}
            secureTextEntry={isPasswordHidden}
            autoCapitalize="none"
            required
          />
          <TouchableOpacity
            style={styles.showHide}
            onPress={() => {
              setIsPasswordHidden(!isPasswordHidden)
            }}
          >
            <Text
              style={[
                globalStyles.text,
                globalStyles.lightTextColor,
                globalStyles.fs10
              ]}
            >
              {isPasswordHidden ? 'SHOW' : 'HIDE'}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={[globalStyles.flexOne, globalStyles.mt4]}>
          <CustomButton
            text="Sign In"
            onPress={handleSignIn}
            isDisabled={isButtonDisabled}
          />
        </View>
        <View
          style={[
            globalStyles.hCenterItems,
            globalStyles.vCenterItems,
            globalStyles.mt4
          ]}
        >
          <View style={globalStyles.hDirection}>
            <TouchableOpacity onPress={() => console.log('Forgot Password...')}>
              <Text style={[globalStyles.lightTextColor, globalStyles.fwLight]}>
                Forgot Password
              </Text>
            </TouchableOpacity>
            <Text
              style={[
                globalStyles.fwLight,
                globalStyles.lightTextColor,
                globalStyles.ml1,
                globalStyles.mr1
              ]}
            >
              |
            </Text>
            <TouchableOpacity onPress={() => console.log('Create Account...')}>
              <Text style={[globalStyles.lightTextColor, globalStyles.fwLight]}>
                Create Account
              </Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={handleGuest}>
            <Text
              style={[
                globalStyles.lightTextColor,
                globalStyles.fwLight,
                globalStyles.mt1
              ]}
            >
              Continue as Guest
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={globalStyles.mt5}
            onPress={() => console.log('Expole EV...')}
          >
            <Text
              style={[
                globalStyles.lightTextColor,
                globalStyles.fwLight,
                globalStyles.textUnderline
              ]}
            >
              Expole EV
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={(globalStyles.hCenterItems, globalStyles.mt5)}
            onPress={() => console.log('Expole EV...')}
          >
            <Text
              style={[
                globalStyles.text,
                globalStyles.lightTextColor,
                globalStyles.fwLight,
                globalStyles.fs12,
                globalStyles.mt8,
                globalStyles.textCenter,
                globalStyles.textUnderline
              ]}
            >
              By using this application, you are accepting the User Terms for
              Application Service and Privacy Statement.
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.footerLogoWrapper}>
          <Image
            source={require('../assets/logos/gm_logo.png')}
            style={styles.footerLogo}
          />
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  headerLogoWrapper: {
    marginTop: 20,
    marginBottom: 40,
    alignItems: 'center'
  },
  headerLogo: {
    width: 140,
    height: 78
  },
  footerLogoWrapper: {
    marginTop: 20,
    marginBottom: 40,
    alignItems: 'center'
  },
  footerLogo: {
    width: 26,
    height: 26,
    opacity: 0.5
  },
  passwordField: {
    position: 'relative',
    marginHorizontal: 0
  },
  showHide: {
    position: 'absolute',
    right: 6,
    bottom: 20
  }
})

export default LoginScreen
