import { Pressable, Text, Platform, View } from 'react-native'
import React, { useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global'

// components
import LoginScreen from '../screens/LoginScreen'
import HomeScreen from '../screens/HomeScreen'

const Stack = createNativeStackNavigator()

const StackNavigator = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isGuest, setIsGuest] = useState(false)

  const navigation = useNavigation()

  const handleSignOut = () => {
    setEmail('')
    setPassword('')
    navigation.goBack()
  }

  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        options={{
          headerShown: false
        }}
        name="Login"
      >
        {(props) => (
          <LoginScreen
            {...props}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setIsGuest={setIsGuest}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: '#212121',
            borderBottomWidth: 0,
            borderBottomColor: '#212121',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'mulish-regular',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerTitleAlign: 'center',
          headerLeft: () => (
            <View style={[{ marginLeft: Platform.OS === 'web' ? 16 : 0 }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          ),
          headerRight: () =>
            !isGuest && (
              <Pressable
                onPress={handleSignOut}
                style={{ marginRight: Platform.OS === 'web' ? 16 : 0 }}
              >
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwLight,
                    globalStyles.whiteTextColor
                  ]}
                >
                  Sign Out
                </Text>
              </Pressable>
            )
        }}
        name="Home"
        component={HomeScreen}
      />
    </Stack.Navigator>
  )
}

export default StackNavigator
