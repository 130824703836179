import { Text, TouchableOpacity, View } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global'

const CustomButton = ({ text, onPress, isDisabled = false }) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={isDisabled}>
      <View
        style={[
          globalStyles.button,
          globalStyles.buttonRouded,
          globalStyles.buttonLarge,
          globalStyles.secondaryBgColor,
          { opacity: isDisabled ? 0.4 : 1 }
        ]}
      >
        <Text
          style={[
            globalStyles.text,
            globalStyles.whiteTextColor,
            globalStyles.textCenter
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export default CustomButton
