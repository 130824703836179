import { StatusBar } from 'expo-status-bar'
import { useFonts } from 'expo-font'
import AppLoading from 'expo-app-loading'
import { NavigationContainer } from '@react-navigation/native'

// components
import StackNavigator from './navigation/StackNavigator'

export default function App() {
  let [fontsLoaded] = useFonts({
    'mulish-bold': require('./assets/fonts/Mulish-Bold.ttf'),
    'mulish-light': require('./assets/fonts/Mulish-Light.ttf'),
    'mulish-regular': require('./assets/fonts/Mulish-Regular.ttf')
  })

  if (!fontsLoaded) return <AppLoading />

  return (
    <NavigationContainer>
      <StackNavigator />
      <StatusBar style="light" />
    </NavigationContainer>
  )
}
